import { isDevelopment, usePrevious } from "@biblioteksentralen/js-utils";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";
import { usePreviewContext } from "../../components/preview/previewContext";
import { isProductionDataset } from "../currentDataset";

// Using a global variable to keep track of site.
// Alternatively logging could be done in a hook and site could be read from the router-context, but that would mean logging could only be done in a function component or in a hook, or the logging function would need to be sent arround as an argument, making it more complicated to do logging.
let siteSubdomain: string | undefined = undefined;
const useSetSiteSubdomain = () => {
  const { site } = useRouter().query;
  if (typeof site === "string") {
    siteSubdomain = site;
  }
};

export function PlausibleSetup() {
  const previewContext = usePreviewContext();

  useSetSiteSubdomain();
  useLogPageview();

  // Sørger for at vi ikke logger metrikker når siden vises som preview i studio
  if (previewContext.isOn !== false) return null;

  return <Script defer data-domain={getPlausibleDomain()} src="https://plausible.io/js/plausible.manual.js" />;
}

export function getPlausibleDomain(): string {
  if (isProductionDataset && !isDevelopment()) return "bibliotek.io";
  if (isDevelopment()) return "bibliotek-local.io";
  return "bibliotek-test.io";
}

export function logEvent(event: string, extraData?: Record<string, string | number | boolean>, retries = 0) {
  try {
    /** @ts-ignore */
    if (typeof plausible === "undefined" || !siteSubdomain) {
      // plausible-scriptet er ikke lastet enda ved første pageview. Bruker derfor retries for å utsette logging til script er lastet
      if (retries < 3) {
        setTimeout(() => logEvent(event, extraData, retries + 1), 1000 * retries);
      } else {
        console.error(
          !siteSubdomain
            ? `Trying to log event "${event}" without subdomain  (3 retries)`
            : `Could not log event "${event}" (3 retries)`
        );
      }
      return;
    }

    const { origin, pathname } = location;
    const path = `${origin}/${siteSubdomain}${pathname}`;

    /** @ts-ignore */
    plausible(event, { u: path, ...(extraData ? { props: extraData } : {}) });
  } catch (e) {
    console.error(e);
  }
}

function useLogPageview() {
  const path = useRouter().asPath;

  const prevLoggedPath = usePrevious(path);
  useEffect(() => {
    if (prevLoggedPath !== path) {
      logEvent("pageview");
    }
  }, [path, prevLoggedPath]);
}
